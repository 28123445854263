import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.i`
  font-size: ${props => props.size && props.size};

  &::before {
    color: ${props => (props.color ? props.color : 'currentColor')} !important;
  }
`;

class Icon extends React.PureComponent {
  render() {
    const { name, color, className, size } = this.props;
    return (
      <Root
        className={`icon icon-${name} ${className}`}
        color={color}
        size={size}
      />
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string
};

export default Icon;
