import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { media, sizes } from '../../styles/media';
import Noscript from './Noscript';

import { CSSTransition } from 'react-transition-group';

const Root = styled.div`
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  display: block;

  width: 100%;
  padding-top: ${props =>
    props.ratioMobile ? props.ratioMobile : props.ratio ? props.ratio : '100'}%;

  ${media.tablet`
    padding-top: ${props => (props.ratio ? props.ratio : '100')}%;
  `};

  .fade-appear {
    opacity: 0.01;
  }
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
`;

const Container = styled.div`
  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: ${props => (props.contain ? 'contain' : 'cover')};

    ${props =>
      props.zoom &&
      `
    transform: scale(1);
    transition: transform 0.35s;

    &:hover {
      transform: scale(1.15);
    }
  `};
  }
`;

class ImageFit extends PureComponent {
  render() {
    const {
      radial,
      gradient,
      ratio,
      ratioMobile,
      src,
      mask,
      zoom,
      contain,
      srcDesktop
    } = this.props;

    return (
      <Root className="image-fit--root">
        <Wrapper
          radial={radial}
          gradient={gradient}
          ratio={ratio}
          ratioMobile={ratioMobile}
          themask={mask}
          className="image-fit--wrapper"
        >
          {srcDesktop && (
            <Container contain={contain} zoom={zoom}>
              <CSSTransition
                key="1"
                classNames="fade"
                appear
                timeout={{ enter: 500, exit: 300 }}
                enter={false}
                exit={false}
              >
                <picture>
                  <source
                    srcSet={srcDesktop}
                    media={`(min-width: ${sizes.desktop}px)`}
                  />
                  <img
                    key={srcDesktop}
                    src={srcDesktop}
                    className="image-fit"
                  />
                </picture>
              </CSSTransition>
              <Noscript>
                <picture>
                  <source
                    srcSet={srcDesktop}
                    media={`(min-width: ${sizes.desktop}px)`}
                  />
                  <img src={srcDesktop} className="image-fit" />
                </picture>
              </Noscript>
            </Container>
          )}

          {!srcDesktop && (
            <Container contain={contain} zoom={zoom}>
              <CSSTransition
                key="1"
                classNames="fade"
                appear
                timeout={{ enter: 500, exit: 300 }}
                enter={false}
                exit={false}
              >
                <img key={src} src={src} className="image-fit" />
              </CSSTransition>
              <Noscript>
                <img src={src} className="image-fit" />
              </Noscript>
            </Container>
          )}
        </Wrapper>
      </Root>
    );
  }
}

ImageFit.defaultProps = {
  radial: false,
  gradient: false,
  mask: false,
  contain: false
};

ImageFit.propTypes = {
  ratio: PropTypes.number,
  ratioMobile: PropTypes.number,
  radial: PropTypes.bool,
  gradient: PropTypes.bool,
  mask: PropTypes.bool,
  zoom: PropTypes.bool,
  contain: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcDesktop: PropTypes.string
};

export default ImageFit;
