import styled from 'styled-components';
import { media } from '../../../../styles/media';
import { FloatingButton } from '../../../Button';
import { Column, Row } from '../../../Grid';
import Section from '../../Section';

export const StyledSection = styled(Section)`
  margin: ${props => props.theme.layout.gutter}px 0;

  ${media.tablet`
    margin: ${props => props.theme.layout.gutter * 3}px 0;
  `};
`;

export const StyledRow = styled(Row)`
  ${media.tablet`
    margin-left: ${props => -props.theme.layout.gutter}px;
    margin-right: ${props => -props.theme.layout.gutter}px;
  `};

  ${media.desktop`
    margin-left: ${props => props.theme.layout.gutter * -2}px;
    margin-right: ${props => props.theme.layout.gutter * -2}px;
  `};
`;

export const StyledColumn = styled(Column)`
  margin-bottom: 0;

  ${media.tablet`
    padding-left: ${props => props.theme.layout.gutter}px;
    padding-right: ${props => props.theme.layout.gutter}px;
    order: ${props => (props.reverse ? 1 : 0)};
  `};

  ${media.desktop`
    padding-left: ${props => props.theme.layout.gutter * 2}px;
    padding-right: ${props => props.theme.layout.gutter * 2}px;
  `};
`;

export const Title = styled.h2`
  color: ${props => props.theme.color.brown};
  position: relative;

  font-family: ${props => props.theme.font.bold};
  font-size: 2.6rem;
  letter-spacing: 0;

  ${media.tablet`
    font-size: 3.5rem;
    line-height: 4rem;
    margin-bottom: ${props => props.theme.layout.gutter / 2}px;
  `};
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: ${props => props.theme.layout.gutter}px;

  ${media.tablet`
    margin-bottom: 0;
  `};

  ${media.desktop`
    width: 570px;
    padding-bottom: 0;

    .image-fit--wrapper {
      padding-bottom: 0;
    }
  `};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50px;
  background-color: white;
  height: 150px;
  width: 150px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
`;

export const P = styled.div`
  font-family: ${props => props.theme.font.medium};
  font-size: 1.2rem;
  letter-spacing: 0.35px;
  line-height: 17.5px;
  text-align: center;

  color: ${props => props.theme.color.darkGrey};

  margin: ${props => props.theme.layout.gutter * 1.5}px 0;
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: ${props => props.theme.layout.gutter * 1.25}px;

  ${FloatingButton} {
    margin: 0 ${props => props.theme.layout.gutter / 4}px;
  }

  ${media.tablet`
    justify-content: flex-start;

    margin-left: ${props => props.theme.layout.gutter / -4}px;
    margin-right: ${props => props.theme.layout.gutter / -4}px;
  `};
`;

export const Content = styled.div`
  margin-top: ${props => props.theme.layout.gutter}px;

  ${P} {
    font-family: ${props => props.theme.font.regular};
    font-size: 1.8rem;
    line-height: 30px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  ${media.tablet`
    margin-top: 0;

    ${Title} {
      margin-top: ${props => props.theme.layout.gutter * 1.25}px;
    }

    > a {
      margin: 0;
    }
  `};

  ${media.desktop`
    ${props =>
      props.reverse
        ? 'padding-left: ' + (props.theme.layout.gutter * 2 + 10) + 'px'
        : null}
    max-width: calc(425px + ${props =>
      props.theme.layout.gutter * 2 + 10 + 'px'});
  `};
`;
