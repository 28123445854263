import styled from 'styled-components';

const Section = styled.section`
  position: relative;

  margin: ${props =>
    props.mood
      ? `${props.theme.layout.gutter * 3}px 0 -${props.theme.layout.gutter}px`
      : `${props.theme.layout.gutter * 3}px 0`};
  margin-bottom: ${props => props.noMarginBottom && '0px'};

  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};

  ${props =>
    props.inverse &&
    `
      margin: 0;
      padding: ${props.theme.layout.gutter * 3}px 0;
      
      background-color: ${props.theme.color.darkWhite};
  `}

  overflow: ${props => (props.mood ? 'hidden' : 'initial')};
  z-index: 0;
`;

export default Section;
