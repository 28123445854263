import styled from 'styled-components';

import { media } from '../../styles/media';

const SubTitle = styled.span`
  display: block;

  font-family: ${props => props.theme.font.black};
  font-size: ${props => props.theme.font.size.subtitle};
  letter-spacing: 4.7px;

  color: ${props => props.theme.color.darkGrey};

  margin-bottom: ${props => props.theme.layout.gutter / 2}px;

  ${media.tablet`
    font-size: 1.5rem;
    letter-spacing: 6px;

    margin-bottom: ${props => props.theme.layout.gutter * 1.25}px;
  `};
`;

export default SubTitle;
