import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Fade from 'react-reveal/Fade';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import styled from 'styled-components';
import { sizes } from '../../../../styles/media';
import theme from '../../../../theme';
import { Button, FloatingButton, WrapperButton } from '../../../Button';
import { Container } from '../../../Grid';
import Icon from '../../../Icon';
import { ImageFit } from '../../../Image';
import Picture from '../../../Picture';
import Text from '../../../Typography/Text';
import Wysiwyg from '../../../Wysiwyg';
import {
  Content,
  ImageWrapper,
  LogoWrapper,
  SocialWrapper,
  StyledColumn,
  StyledRow,
  StyledSection,
  Title
} from './styles';

class SmartMedia extends PureComponent {
  getIcon = name => {
    if (!name) return null;
    const ref = name.trim().toLowerCase();
    if (ref.includes('facebook')) {
      return 'facebook';
    } else if (ref.includes('linkedin')) {
      return 'linkedin';
    } else if (ref.includes('twitter')) {
      return 'twitter';
    } else if (ref.includes('google')) {
      return 'google';
    } else if (ref.includes('youtube')) {
      return 'video';
    } else if (ref.includes('instagram')) {
      return 'instagram';
    } else if (ref.includes('pinterest')) {
      return 'pinterest';
    } else {
      return null;
    }
  };

  renderSocial = ({ url, name }, index) => {
    return (
      <FloatingButton key={index} href={url} target="_blank">
        <Icon name={this.getIcon(name)} />
      </FloatingButton>
    );
  };

  renderSocials = () => {
    return this.props.socials.map(this.renderSocial);
  };

  renderButton = () => {
    const { link, linkLabel, buttonCenter, buttonInfo } = this.props;

    if (buttonCenter) {
      return linkLabel && <Button href={link}>{linkLabel}</Button>;
    } else {
      return linkLabel && <Button href={link}>{linkLabel}</Button>;
    }
  };

  renderLogo = () => {
    const { logo, logoMobile } = this.props;

    return (
      logo && (
        <LogoWrapper>
          <Image
            src={logo}
            width={100}
            height={100}
            objectFit="contain"
            unoptimized
            priority
          />
        </LogoWrapper>
      )
    );
  };

  render() {
    const { title, content, socials, reverse, image, imageMobile } = this.props;

    return (
      <StyledSection>
        <Container>
          <StyledRow>
            <StyledColumn tablet={2} desktop={2} reverse={reverse}>
              <Fade up>
                <ImageWrapper>
                  <ImageFit
                    src={imageMobile || image}
                    srcDesktop={image}
                    ratio={107}
                    ratioMobile={87}
                  />
                  {this.renderLogo()}
                </ImageWrapper>
              </Fade>
            </StyledColumn>
            <StyledColumn tablet={2} desktop={2} reverse={false}>
              <Fade up>
                <Content reverse={reverse}>
                  {title && (
                    <Text
                      variant="heading2"
                      as="h2"
                      color={theme.color.brand.primary}
                      marginBottom="10px"
                    >
                      {title}
                    </Text>
                  )}
                  <Wysiwyg content={content} bigTitle />
                  {this.renderButton()}
                  {socials && (
                    <SocialWrapper>{this.renderSocials()}</SocialWrapper>
                  )}
                </Content>
              </Fade>
            </StyledColumn>
          </StyledRow>
        </Container>
      </StyledSection>
    );
  }
}

SmartMedia.defaultProps = {
  reverse: false,
  buttonInfo: true
};

SmartMedia.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  logo: PropTypes.string,
  logoMobile: PropTypes.string,
  content: PropTypes.string.isRequired,
  linkLabel: PropTypes.string,
  link: PropTypes.string,
  socials: PropTypes.array,
  reverse: PropTypes.bool,
  buttonCenter: PropTypes.bool,
  buttonInfo: PropTypes.bool
};

export default SmartMedia;
