import PropTypes from 'prop-types';
import React from 'react';
import { Root } from './style';

const Wysiwyg = ({ color, content, bigTitle, className }) => {
  return (
    <Root
      color={color}
      bigTitle={bigTitle}
      dangerouslySetInnerHTML={{ __html: content }}
      className={className}
    />
  );
};

Wysiwyg.defaultProps = {
  bigTitle: false,
  className: ''
};

Wysiwyg.propTypes = {
  content: PropTypes.string,
  color: PropTypes.string,
  bigTitle: PropTypes.bool,
  className: PropTypes.string
};

export default Wysiwyg;
