import React from 'react';
import PropTypes from 'prop-types';

import { Root, WrapperImage, WrapperText, Title } from './style';
import Wysiwyg from '../../../Wysiwyg';
import { Button } from '../../../Button';
import Picture from '../../../Picture';
import { sizes } from '../../../../styles/media';

const IntroMedia = ({
  img,
  imgDesktop,
  title,
  children,
  reverse,
  link,
  linkLabel
}) => (
  <Root reverse={reverse}>
    <WrapperImage>
      <Picture
        src={img}
        pictures={[{ src: imgDesktop, minWidth: sizes.desktop }]}
      />
    </WrapperImage>
    <WrapperText reverse={reverse}>
      {title && <Title>{title}</Title>}
      <Wysiwyg content={children} bigTitle />
      {link && linkLabel && <Button href={link}>{linkLabel}</Button>}
    </WrapperText>
  </Root>
);

IntroMedia.defaultProps = {
  reverse: false
};

IntroMedia.propTypes = {
  img: PropTypes.string,
  imgDesktop: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  reverse: PropTypes.bool,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  targetBlank: PropTypes.bool
};

export default IntroMedia;
