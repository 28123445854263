import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import { media } from '../../styles/media';

import color from '../../theme/color';
import Icon from '../Icon';

const Header = styled.div`
  position: relative;

  .parallax {
    display: none;
    position: absolute !important;
    top: 50%;
    right: 0;

    height: 190px !important;

    transform: translate3d(0, -50%, 0);
    pointer-events: none;

    [class^='parallax-banner-layer'] {
      width: 120px;
      right: auto !important;
      background-size: contain !important;
      background-repeat: no-repeat;
    }
  }

  .parallax_shop_under {
    z-index: 0;

    .parallax-banner-layer-0 {
      background-position: left 35% !important;
      left: auto !important;
    }

    .parallax-banner-layer-1 {
      display: none;
      left: auto !important;
    }
  }

  ${media.desktop`
  .parallax {
    width: 450px;
    height: 250px !important;
    display: block;

    [class^='parallax-banner-layer'] {
      width: 185px;
    }
  }

  .parallax_shop_under {
    .parallax-banner-layer-0 {
      right: 0px !important;
      background-position: left 30% !important;
    }

    .parallax-banner-layer-1 {
      display: block;
      width: 80px;

      right: 160px !important;
      background-position: left 20% !important;
    }
  }

  .parallax_shop_list {
    .parallax-banner-layer-0 {
      top: 30px !important;
    }

    .parallax-banner-layer-1 {
      top: 50px !important;
    }
  }
`};
`;

const Root = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  font-family: ${props => props.theme.font.bold};
  font-size: 1.8rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.24px;

  color: ${props =>
    props.type ? props.theme.color[props.type] : props.theme.color.secondary};

  margin-bottom: ${props => props.theme.layout.gutter * 1.5}px;

  &:after {
    display: block;
    align-self: center;

    content: '';

    flex: 1 1 0%;
    width: 100%;
    height: 5px;
    margin-left: ${props => props.theme.layout.gutter}px;

    background-color: ${props => props.theme.color.darkWhite};
  }

  > .icon {
    margin-right: ${props => props.theme.layout.gutter / 2}px;
  }

  ${media.tablet`
    font-family: ${props => props.theme.font.medium};
    font-size: 2.8rem;
    letter-spacing: 2px;

    margin-bottom: ${props => props.theme.layout.gutter * 2}px;

    &:after {
      height: 10px;
    }
  `};
`;

class ParallaxTitle extends React.PureComponent {
  render() {
    const { children, icon, type, parallax, color, className } = this.props;

    return (
      <Header>
        <Root type={type} color={color}>
          <Icon name={icon} />
          {children}
        </Root>
        {parallax && (
          <ParallaxProvider>
            <ParallaxBanner
              className={`parallax parallax_under parallax_shop_under ${className}`}
              layers={[
                {
                  image: parallax[0],
                  amount: 0.2,
                  slowerScrollRate: true
                },
                {
                  image: parallax[1],
                  amount: 0.3,
                  slowerScrollRate: true
                }
              ]}
            />
          </ParallaxProvider>
        )}
      </Header>
    );
  }
}

ParallaxTitle.defaultProps = {
  border: false,
  color: color.black
};

ParallaxTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  type: PropTypes.string,
  border: PropTypes.bool,
  parallax: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};

export default ParallaxTitle;
