import styled from 'styled-components';

import { media } from '../../../../styles/media';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;

  ${media.desktop`
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  `};
`;

export const WrapperImage = styled.div`
  margin-bottom: 4rem;

  ${media.desktop`
    flex: 1.10;
    margin-bottom: 0;
  `};

  & > picture {
    img {
      width: 100%;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const WrapperText = styled.div`
  padding: 0 ${props => props.theme.layout.gutter * 0.75}px;

  ${media.desktop`
    flex: 0.90;
    ${props => `
      ${props.reverse ? 'padding : 0 13rem 0 0' : 'padding: 0 0 0 13rem'}
    `}
  `};
`;

export const Title = styled.h2`
  color: ${props => props.theme.color.brown};
  font-family: ${props => props.theme.font.bold};
  font-size: 2.4rem;
  margin-bottom: 2rem;

  ${media.desktop`
    font-size: 3.5rem;
    line-height: 4rem;
  `};
`;
