import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Typography/Text';
import theme from '../../theme';

class H3 extends React.PureComponent {
  render() {
    const { children, icon, border, color, className } = this.props;
    return (
      <Text
        color={color || theme.color.brand.primary}
        as="h3"
        variant="heading2"
        marginBottom="20px"
      >
        {children}
      </Text>
    );
  }
}

H3.defaultProps = {
  border: false
};

H3.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  border: PropTypes.bool
};

export default H3;
