import { media } from '../../../src/styles/media';
import styled from 'styled-components';

export const Root = styled.div`
  a {
    color: ${props => props.theme.color.brand.primary};
  }
  a:visited {
    color: ${props => props.theme.color.brand.primary};
  }
  h1 {
    font-family: ${props => props.theme.font.heading1.fontFamily};
    font-size: ${props => props.theme.font.heading1.fontSize};
    line-height: ${props => props.theme.font.heading1.lineHeight};
    color: ${props => props.color || props.theme.color.brand.primary};
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  h2 {
    font-family: ${props => props.theme.font.heading2.fontFamily};
    font-size: ${props => props.theme.font.heading2.fontSize};
    line-height: ${props => props.theme.font.heading2.lineHeight};
    color: ${props => props.color || props.theme.color.brand.primary};
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  h3 {
    font-family: ${props => props.theme.font.heading3.fontFamily};
    font-size: ${props => props.theme.font.heading3.fontSize};
    line-height: ${props => props.theme.font.heading3.lineHeight};
    color: ${props => props.color || props.theme.color.brand.primary};
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  h4 {
    font-family: ${props => props.theme.font.heading3.fontFamily};
    font-size: ${props => props.theme.font.heading3.fontSize};
    line-height: ${props => props.theme.font.heading3.lineHeight};
    color: ${props => props.color || props.theme.color.brand.primary};
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  h5 {
    font-family: ${props => props.theme.font.heading3.fontFamily};
    font-size: ${props => props.theme.font.heading3.fontSize};
    line-height: ${props => props.theme.font.heading3.lineHeight};
    color: ${props => props.color || props.theme.color.brand.primary};
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  p {
    font-family: ${props => props.theme.font.body.fontFamily};
    font-size: ${props => props.theme.font.body.fontSize};
    line-height: ${props => props.theme.font.body.lineHeight};
    color: ${props => props.color || props.theme.color.brand.grey};
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  strong {
    font-weight: 600;
    color: ${props => props.theme.color.brand.primary};
  }

  em {
    font-style: italic;
  }

  img {
    margin-bottom: ${props => props.theme.layout.gutter}px;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }

  ul {
    margin-bottom: ${props => props.theme.layout.gutter}px;
  }

  li {
    color: ${props => props.theme.color.brand.grey};
    padding-left: 1.5rem;
    position: relative;

    &:before {
      background-color: ${props =>
        props.color || props.theme.color.brand.primary};
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      height: 6px;
      width: 6px;
    }
  }

  ${media.tablet`
    h2 {
      ${props =>
        props.bigTitle &&
        `
          margin-top: ${props.theme.layout.gutter * 3}px;
          margin-bottom: ${props.theme.layout.gutter}px;
        `}
    }

    p, li {
      font-family: ${props => props.theme.font.body.fontFamily};
      line-height: ${props => props.theme.font.body.lineHeight};
      font-size: ${props => props.theme.font.body.fontSize};
      color: ${props => props.color || props.theme.color.brand.grey};
    }


    li {
      padding-left: 2rem;

      &:before {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  `};
`;
