import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../../../styles/media';
import { Button } from '../../../Button';
import { Column, Row } from '../../../Grid';
import { ImageFit } from '../../../Image';
import { SubTitle, Title } from '../../../Title';

const Content = styled.div`
  ${Title} {
    margin: ${props => props.theme.layout.gutter}px 0
      ${props => props.theme.layout.gutter / 2}px;
  }

  padding: 0 ${props => props.theme.layout.gutter * 0.75}px;
  text-align: center;

  ${media.desktop`
    text-align: left;
    padding: 0;

    ${Title} {
      text-align: left;

      margin-top: ${props => props.theme.layout.gutter * 3}px;
      margin-bottom: ${props => props.theme.layout.gutter}px;
    }

    a, button { margin: ${props => props.theme.layout.gutter * 1.75}px 0 0; }
  `};
`;

const P = styled.p`
  margin-bottom: ${props => props.theme.layout.gutter}px;

  font-family: ${props => props.theme.font.body.fontFamily};
  font-size: ${props => props.theme.font.body.fontSize};
  line-height: ${props => props.theme.font.body.lineHeight};

  ${props =>
    props.smallTitle &&
    `
    text-align: left;
  `}

  color: ${props => props.theme.color.brand.grey};

  ${media.desktop`
    width: 80%;
  `};
`;

export class Simple extends PureComponent {
  render() {
    const {
      children,
      title,
      subTitle,
      img,
      imgDesktop,
      link,
      linkLabel,
      smallTitle,
      textEditor,
      directLink,
      radial,
      className
    } = this.props;

    return (
      <Row className={className}>
        <Column desktop={2}>
          <ImageFit src={img} srcDesktop={imgDesktop} radial={radial} />
        </Column>
        <Column desktop={2}>
          <Content>
            {(title || subTitle) && (
              <Title smallTitle={smallTitle}>
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
                {title && title}
              </Title>
            )}
            {textEditor && children}
            {!textEditor && <P smallTitle={smallTitle}>{children}</P>}
            {link && linkLabel && <Button href={link}>{linkLabel}</Button>}
          </Content>
        </Column>
      </Row>
    );
  }
}

Simple.defaultProps = {
  smallTitle: false,
  directLink: false,
  textEditor: false
};

Simple.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  textEditor: PropTypes.bool,
  smallTitle: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  img: PropTypes.string.isRequired,
  imgDesktop: PropTypes.string,
  link: PropTypes.string,
  directLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  radial: PropTypes.bool
};
