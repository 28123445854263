import styled from 'styled-components';
import { media } from '../../styles/media';

const H2 = styled.h2`
  font-family: ${props => props.theme.font.bold};
  font-size: 5rem;
  line-height: 5.55rem;
  letter-spacing: 0.65px;

  text-transform: uppercase;

  color: ${props => props.theme.color.white};

  ${media.tablet`
    font-size: 7.5rem;
    letter-spacing: 1px;
    line-height: 7.3rem;
  `};
`;

export default H2;
