import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { media } from '../../styles/media';

import Icon from '../Icon';
import { ImageFit } from '../Image';

const Wrapper = styled.h4`
  font-family: ${props => props.theme.font.medium};
  color: ${props => props.theme.color.blue};

  font-size: 2rem;
  letter-spacing: 0.4px;
  line-height: 1.7rem;
  text-align: center;

  margin-bottom: ${props => props.theme.layout.gutter / 2}px;

  ${media.tablet`
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    text-align: left;
  `};
`;

const CustomIcon = styled(Icon)`
  font-size: 1.5rem;

  margin-right: ${props => props.theme.layout.gutter / 2}px;

  ${media.tablet`
    font-size: ${props => (props.name == 'plan' ? 2 : 1.75)}rem;
    font-weight: ${props => (props.name == 'plan' ? 'bold' : 'normal')};
  `};
`;

const ImageContainer = styled.span`
  display: inline-block;
  width: ${props => props.theme.layout.gutter}px;
  margin-right: ${props => props.theme.layout.gutter / 2}px;
`;

class H4 extends React.PureComponent {
  render() {
    const { children, icon, image } = this.props;
    return (
      <Wrapper>
        {icon && <CustomIcon name={icon} />}
        {image && (
          <ImageContainer>
            <ImageFit src={image} />
          </ImageContainer>
        )}
        {children}
      </Wrapper>
    );
  }
}

H4.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  icon: PropTypes.string,
  image: PropTypes.string
};

export default H4;
