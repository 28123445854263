import styled from 'styled-components';

import { media } from '../../styles/media';

const Title = styled.h2`
  font-family: ${props => props.theme.font.heading2.fontFamily};
  font-size: ${props => props.theme.font.heading2.fontSize};
  line-height: ${props => props.theme.font.heading2.lineHeight};
  text-align: center;

  color: ${props => props.theme.color.brand.primary};

  margin-bottom: ${props =>
    props.slider
      ? `${props.theme.layout.gutter / 2}px`
      : `${props.theme.layout.gutter}px`};

  ${media.tablet`
    margin-bottom: ${props => props.theme.layout.gutter * 3}px;
    margin-bottom: ${props =>
      props.slider
        ? `${props.theme.layout.gutter * 1.5}px`
        : `${props.theme.layout.gutter * 3}px`};
  `};
`;

export default Title;
