import PropTypes from 'prop-types';
import React from 'react';

const Picture = ({ src, pictures }) => (
  <picture>
    {pictures.map((picture, index) => (
      <source
        key={index}
        srcSet={picture.src}
        media={`(min-width: ${picture.minWidth}px`}
      />
    ))}
    <img src={src} />
  </picture>
);

Picture.propTypes = {
  src: PropTypes.string,
  pictures: PropTypes.array
};

export default Picture;
